export default {
  colors: {
    primary: '#F26C21',
    secondary: '#bdc3c7',
    red: '#E91E63',
    grey: '#d7d7d7',
  },
  borders: {
    light: '1px solid #E8E8E8',
  },
  fontSizes: {
    xs: '0.8rem',
  },
  zIndices: [0, 1, 2, 3, 4, 99],
};
