import * as React from 'react';
import userPool from '../aws-config';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../shared/button';
import { sendMessage } from '../utils';

const Container = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  display: block;
  width: 550px;
  padding: 2.5em;

  fieldset {
    border: 0;
    padding: 0;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.3em;
`;

const Spacer = styled.div`
  height: 24px;
`;

const Actions = styled.div`
  display: flex;

  button:first-child {
    margin-right: 1em;
  }
`;

function Logout() {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const onCancel = () => {
    sendMessage('logoutCancel');
    history.goBack();
  }

  const onLogout = async () => {
    const user = userPool.getCurrentUser();
    setSubmitting(true);

    if (user != null) {
      user.signOut();
      sendMessage('logoutSuccess', true);
      history.push('/login');
    } else {
      sendMessage('logoutNoCurrentUser', false);
      setSubmitting(false);
    }
  }

  return (
    <Container>
      <Card>
        <Heading>Are you sure you want to logout?</Heading>
        <Spacer />
        <Actions>
          <Button bg="secondary" type="button" onClick={onCancel}>Cancel</Button>
          <Button type="button" onClick={onLogout} disabled={isSubmitting}>Logout</Button>
        </Actions>
      </Card>
    </Container>
  );
}

export default Logout;
