import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import styled from 'styled-components';
import Button from '../shared/button';
import Input from '../shared/input';
import userPool from '../aws-config';
import theme from '../theme';

const Container = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  display: block;
  width: 550px;
  padding: 2.5em;
  text-align: ${props => props.center ? 'center' : 'none'};

  fieldset {
    border: 0;
    padding: 0;
  }
`;

const Spacer = styled.div`
  height: ${props => props.height || '24px'};
`;

const Footer = styled.section`
  text-align: center;

  a, span {
    text-decoration: none;
    margin-right: 1em;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.3em;
`;

const Sub = styled.p`
  margin-top: 0;
`;

const ErrorMsg = styled.p`
  color: ${theme.colors.red};
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object().shape({
  firstName: yup.string()
    .required('First name is required'),
  lastName: yup.string()
    .required('Last name is required'),
  phoneNumber: yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .length(10, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  email: yup.string()
    .email()
    .required('Email is required'),
  password: yup.string()
    .min(6, 'Password is too short, must be at least 6 characters.')
    .required('Password is required'),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), null], 'Password must be same')
    .required('Password confirm is required'),
  accountCode: yup.number()
});


function createAttrsList(values) {
  const attrs = [];

  for(const [key, value] of Object.entries(values)) {
    if (key === 'firstName') {
      attrs.push( new CognitoUserAttribute({ Name: 'given_name', Value: value }) );
    }

    if (key === 'lastName') {
      attrs.push( new CognitoUserAttribute({ Name: 'family_name', Value: value }) );
    }

    if (key === 'phoneNumber') {
      attrs.push( new CognitoUserAttribute({ Name: 'phone_number', Value: `+1${value}` }) );
    }

    if (key === 'accountCode') {
      attrs.push( new CognitoUserAttribute({ Name: 'custom:account_code', Value: value }) );
    }
  }

  return attrs;
}

function renderCheckEmailMsg(email) {
  return (
    <Container>
      <Card center>
        <Heading>Please activate your account</Heading>
        <Sub>We have sent an email with a confirmation link to your email address.</Sub>
        <Spacer />
        <Footer>
          <small>
            <Link to="/login">Login</Link>
          </small>
        </Footer>
      </Card>
    </Container>
  );
}

function Signup() {
  const [successEmail, setSuccessEmail] = React.useState(null);

  if(successEmail != null) {
    return renderCheckEmailMsg();
  }

  return (
    <Container>
      <Card>
        <Heading>Create an account</Heading>
        <Sub>Complete the form below to create your account and unlock our subscription plans.</Sub>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            passwordConfirm: '',
            accountCode: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const attrList = createAttrsList(values);

            userPool.signUp(values.email, values.password, attrList, null, function(err, result) {
              if(err) {
                setErrors({ aws: err.message });
                setSubmitting(false);
                return;
              }

              setSuccessEmail(true);
            });
          }}
        >
          {
            ({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <fieldset>
                <ErrorMsg>
                  {
                    errors.firstName ||
                    errors.lastName ||
                    errors.phoneNumber ||
                    errors.email ||
                    errors.password ||
                    errors.aws ||
                    errors.passwordConfirm ||
                    errors.accountCode
                  }
                </ErrorMsg>
                <Input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  placeholder="First Name"
                />
                <Input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  placeholder="Last Name"
                />
                <Input
                  type="text"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  placeholder="Phone number"
                />
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email"
                />
                <Input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Password"
                />
                <Input
                  type="password"
                  name="passwordConfirm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  placeholder="Confirm Password"
                />
                <Input
                  type="text"
                  name="accountCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accountCode}
                  placeholder="Account code (optional)"
                />
                <Spacer />
                <Button type="button" onClick={handleSubmit} disabled={isSubmitting}>Continue</Button>
                <Spacer />
                <Footer>
                  <small>
                    <Link to="/login">Login</Link>
                    <span>-</span>
                    <Link to="/forgot-password">Forgotten Password</Link>
                  </small>
                </Footer>
              </fieldset>
            )}
        </Formik>
      </Card>
    </Container>
  );
}

export default Signup;
