import { createGlobalStyle } from 'styled-components';
import SF from '../assets/fonts/SF.woff';

const device = {
  small: '(max-width: 768px)'
};

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'SF';
    src: local('SF'), url(${SF}) format('woff');
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 1.1vw;

    @media ${device.small} {
      font-size: 100%;
    }
  }

  body {
    font-family: 'SF', -apple-system, sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: black;
    margin: 0;
  }

  #site-wrapper {
    height: 100%;
    min-height: 100vh;
  }

  a {
    color: black;

    &:focus {
      outline: 1px solid #414141;
      outline-offset: 2px;
    }
  }

  small {
    font-size: 0.76rem;
  }

  input {
    font-size: inherit;
    font-family: inherit;
  }

  button {
    font-size: inherit;
    font-family: inherit;

    &:focus {
      outline: 1px dotted #414141;
      outline-offset: 2px;
    }
  }
`;

export default GlobalStyles;
