import styled from 'styled-components';
import theme from '../theme';

const Input = styled.input`
  min-width: 36px;
  min-height: 36px;
  width: 100%;
  border: 0;
  border: 1px solid ${theme.colors.grey};
  padding: 12px 10px;
  margin-bottom: 0.7em;

  &:focus {
    outline: 0;
    border: 1px solid black;
  }

  &:disabled {
    border-color: #ececec;
    cursor: not-allowed;
  }
`;

export default Input;
