import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../shared/button';
import Input from '../shared/input';
import userPool from '../aws-config';
import theme from '../theme';
import { sendMessage } from '../utils';

const Container = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  display: block;
  width: 550px;
  padding: 2.5em;

  fieldset {
    border: 0;
    padding: 0;
  }
`;

const Spacer = styled.div`
  height: ${props => props.height || '24px'};
`;

const Footer = styled.section`
  text-align: center;

  a, span {
    text-decoration: none;
    margin-right: 1em;
  }
`;

const ErrorMsg = styled.p`
  color: ${theme.colors.red};
`;

const schema = yup.object().shape({
  email: yup.string()
    .email()
    .required('email is required'),
  password: yup.string()
    .required('password is required'),
});

function Login() {
  const history = useHistory();

  React.useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      history.push('/?redirect=session');
    }

  }, [history]);

  return (
    <Container>
      <Card>
        <h2>I already have an account</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const cognitoUser = new CognitoUser({ Username: values.email, Pool: userPool });
            const authDetails = new AuthenticationDetails({ Username: values.email, Password: values.password });
            cognitoUser.authenticateUser(authDetails, {
              onSuccess: session => {
                sendMessage('session', session);
                history.push('/?redirect=session');
              },
              onFailure: err => {
                setErrors({ aws: err.message });
                setSubmitting(false);
              }
            });
          }}
        >
          {
            ({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <fieldset>
                <ErrorMsg>
                  {errors.email || errors.password || errors.aws }
                </ErrorMsg>
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email"
                />
                <Input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Password"
                />
                <Spacer />
                <Button type="button" onClick={handleSubmit} disabled={isSubmitting}>Log in</Button>
                <Spacer />
                <Footer>
                  <small>
                    <Link to="/signup">Create an account</Link>
                    <span>-</span>
                    <Link to="/forgot-password">Forgotten Password</Link>
                  </small>
                </Footer>
              </fieldset>
            )}
        </Formik>
      </Card>
    </Container>
  );
}

export default Login;
