import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from '../aws-config';
import styled from 'styled-components';
import Button from '../shared/button';
import Input from '../shared/input';
import theme from '../theme';

const Container = styled.div`
  margin: 0 auto;
  max-width: 60rem;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  display: block;
  width: 550px;
  padding: 2.5em;
  text-align: ${props => props.center ? 'center' : 'none'};

  fieldset {
    border: 0;
    padding: 0;
  }
`;

const Spacer = styled.div`
  height: ${props => props.height || '24px'};
`;

const Footer = styled.section`
  text-align: center;

  a, span {
    text-decoration: none;
    margin-right: 1em;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.3em;
`;

const Sub = styled.p`
  margin-top: 0;
`;

const ErrorMsg = styled.p`
  color: ${theme.colors.red};
`;

const schema = yup.object().shape({
  email: yup.string()
    .email()
    .required('Email is required')
});

const resetSchema = yup.object().shape({
  password: yup.string()
    .min(6, 'Password is too short, must be at least 6 characters.')
    .required('Password is required'),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), null], 'Password must be same')
    .required('Password confirm is required'),
});

function renderCheckEmailMsg(email) {
  return (
    <Container>
      <Card>
        <Sub>A link to reset your password has been sent to <a href={`mailto:${email}`}>{email}</a>.</Sub>
        <Spacer />
        <Footer>
          <small>
            <Link to="/login">Login</Link>
            <span>-</span>
            <Link to="/forgot-password">Forgotten Password</Link>
          </small>
        </Footer>
      </Card>
    </Container>
  );
}

function renderPasswordResetSuccess(email) {
  return (
    <Container>
      <Card center>
        <Sub>Your password has been reset!</Sub>
        <Footer>
          <small><Link to="/login">← Back to Login</Link></small>
        </Footer>
      </Card>
    </Container>
  );
}

function renderForgotPasswordForm(setSuccessEmail) {
  return (
    <Container>
      <Card>
        <Heading>Forgot your password?</Heading>
        <Sub>Enter the email associated with your account and we will send you a password reset link.</Sub>
        <Formik
          initialValues={{ email: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const cognitoUser = new CognitoUser({ Username: values.email, Pool: userPool });

            cognitoUser.forgotPassword({
              onSuccess: function(res) {
                setSuccessEmail(values.email);
              },
              onFailure: function(err) {
                setErrors({ aws: err.message });
                setSubmitting(false);
              }
            });
          }}
        >
          {
            ({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <fieldset>
              <ErrorMsg>
                { errors.email || errors.aws }
              </ErrorMsg>
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Email"
              />
              <Spacer />
              <Button type="button" onClick={handleSubmit} disabled={isSubmitting}>Send Reset Link</Button>
            </fieldset>
          )}
        </Formik>
        <Spacer />
        <Footer>
          <small>
            <Link to="/login">Login</Link>
            <span>-</span>
            <Link to="/signup">Create an Account</Link>
          </small>
        </Footer>
      </Card>
    </Container>
  );
}

function renderResetForm(code, email, setSuccessReset) {
  return (
    <Container>
      <Card>
        <Sub>Please enter a new password.</Sub>
        <Formik
          initialValues={{ password: '', passwordConfirm: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={resetSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
            cognitoUser.confirmPassword(code, values.password, {
              onSuccess: () => {
                setSuccessReset(true);
              },
              onFailure: (err) => {
                setErrors({ aws: err.message });
                setSubmitting(false);
              }
            });
          }}
        >
          {
            ({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <fieldset>
                <ErrorMsg>
                  {
                    errors.password ||
                    errors.passwordConfirm ||
                    errors.aws
                  }
                </ErrorMsg>
                <Input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Password"
                />
                <Input
                  type="password"
                  name="passwordConfirm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  placeholder="Re-enter Password"
                />
                <Spacer />
                <Button type="button" onClick={handleSubmit} disabled={isSubmitting}>Reset Password</Button>
              </fieldset>
          )}
        </Formik>
      </Card>
    </Container>
  );
}

function ForgotPassword() {
  const location = useLocation();
  const { search } = location;
  const params = new Map(
    search
    .slice(1)
    .split('&')
    .filter(Boolean)
    .map(item => item.split('='))
    .map(pair => pair.map(decodeURIComponent))
  )

  const hasCodeParam = params.get('code');
  const hasEmailParam = params.get('email');

  const [successEmail, setSuccessEmail] = React.useState(null);
  const [successReset, setSuccessReset] = React.useState(null);

  if(successEmail != null) {
    return renderCheckEmailMsg(successEmail);
  }

  if(successReset != null) {
    return renderPasswordResetSuccess();
  }

  if(hasCodeParam != null && hasEmailParam != null) {
    return renderResetForm(hasCodeParam, hasEmailParam, setSuccessReset);
  }

  return renderForgotPasswordForm(setSuccessEmail);
}

export default ForgotPassword;
