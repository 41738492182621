import userPool from './aws-config';
import React from 'react';

const parentOrigin = window !== window.top && document.referrer ?  new URL(document.referrer).origin : null;

class EventType {
  listeners = new Set();

  emit(data) {
    this.listeners.forEach(listener => {
      listener(data);
    });
  }

  on(handler) {
    const listener = data => handler(data);

    this.listeners.add(listener);

    return () => this.listeners.delete(listener);
  }

  use(handler, deps = undefined) {
    React.useEffect(() => this.on(handler), deps || [handler])
  }
}

const whitelistDomains = [
  'http://localhost:1337',
  'http://sfr.hub',
  'http://localhost:3000'
];

function checkOrigin(origin) {
  if (origin == null) {
    return false;
  }

  return whitelistDomains.includes(origin);
}

function getSendMessage(domain) {
  const sendMessage = (action, payload) => {
    window.parent.postMessage({ action, payload }, domain);
  };

  const noop = () => {};

  return checkOrigin(domain) ? sendMessage : noop;
}

function getCognitoUserSession() {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser == null) return Promise.resolve(null);

  return cognitoUser.getSession((err, session) => {
    if (err) {
      console.log(err.message);
      return null;
    }

    return session;
  });
}

const crossOriginMessage = new EventType();
const sendMessage = getSendMessage(parentOrigin);

export {
  getSendMessage,
  getCognitoUserSession,
  checkOrigin,
  crossOriginMessage,
  sendMessage
};

