import * as React from 'react';
import { useHistory } from 'react-router-dom';
import userPool from '../aws-config';
import { request } from '../api';

function getJwt(session) {
  if (session.getJwtToken) {
    return session.getJwtToken();
  } else if (session.getSignInUserSession) {
    session = session.getSignInUserSession();
    return session && getJwt(session);
  } else if (session.getAccessToken) {
    session = session.getAccessToken();
    return session && getJwt(session);
  } else {
    return null;
  }
}

function getRefresh(user) {
  const token = user.getCognitoUserSession().getRefreshToken();
  return token || null;
}

function refresh(user) {
  return new Promise((resolve, reject) => {
    const refresh = getRefresh(user);
    if (!refresh || !refresh.getToken()) {
      reject(new Error("Must be logged in"));
    }

    user.refreshSession(refresh, (err) => err ? reject(err) : resolve(null));
  })
}

function getSession(user) {
  return new Promise((resolve, reject) => {
    user.getSession((err, session) => err ? reject(err) : resolve(session));
  });
}


window.$gu = userPool.getCurrentUser()
async function fetchJwt(user, jwt) {
  if (!jwt) {
    const session = await getSession(user);
    jwt = getJwt(session);
    if (!jwt) {
      await refresh(user).catch(error => {
        console.error(error);
      });
      jwt = getJwt(session);
    }


  }


  if (!jwt) {
    throw new Error(`Unable to get credentials after refresh.`);
  }

  return jwt;
}

async function fetchAuthSession(user, jwt) {
  if (user && !jwt) {
    jwt = await fetchJwt(user, jwt).catch(error => {
      console.error('Failed to fetch JWT for user. Not logged in.', error);
    });
  }

  const sessInfo = await request('POST', '/api/session', {
    body: {
      cognito_token: jwt,
      domain: 'example.com'
    },
  });

  return sessInfo;
}

function Session() {
  const history = useHistory();
  const user = userPool.getCurrentUser();
  const jwt = user && getJwt(user);
  const [info, setInfo] = React.useState(null);

  React.useEffect(() => {
    fetchAuthSession(user, jwt)
      .then(info => {
        setInfo(info);
      })
      .catch(err => {
        history.push('/login');
        console.error('Failed to acquire session in <Session />', err)
      });

  }, [jwt]);

  return <pre>{JSON.stringify(info, null, 2)}</pre>;
}

export default Session;
