import styled, { css } from 'styled-components';
import theme from '../theme';

const colors = {
  primary: theme.colors.primary,
  secondary: theme.colors.secondary,
};

const bgColorMixin = props => {
  const color = colors[props.bg];

  if (color == null) {
    throw new Error(`Invalid prop "${props.bg}" supplied to "Button" component.`);
  }

  return css`
    background-color: ${color};
  `;
}

const Button = styled.button`
  min-width: 36px;
  min-height: 36px;
  padding: 12px 10px;
  width: 100%;
  font-size: inherit;
  text-align: center;
  color: white;
  border: 0;
  ${bgColorMixin}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

Button.defaultProps = {
  bg: 'primary'
};

export default Button;
