import fetch from 'isomorphic-fetch';

const percent = encodeURIComponent;

const middleware = [
  function mwQuery(params) {
    if (params.query) {
      let queryString = Object.entries(params.query).map(([key, value]) => `${percent(key)}=${percent(value)}`).join('&')

      params.url = params.url.replace(/\?|$/, () => '?' + queryString)
    }
  },

  function mwBody(params) {
    if (params.body !== undefined) {
      params.body = JSON.stringify(params.body)
      params.headers = {...params.headers, 'content-type': 'application/json'}
    }
  }
];

function callFetch({url, ...params}) {
  return fetch(url, params);
}

class AuthApiError extends Error {
  constructor(params, status, body) {
    super(`${params.method.toUpperCase()} ${params.url} failed with ${status}`);
    this.status = status;

    this.body = body;
  }

  toHumanString() {
    // TODO: implement based on this.body error format
    return super.toString();
  }
}

export async function request(method, url, opts = {}) {
  let params = { ...opts, method, url, };
  for (const mw of middleware) {
    params = mw(params) || params;
  }

  const res = await callFetch(params);

  const contentType = res.headers.get('content-type');

  let body;
  if (/application\/json/gi.test(contentType)) {
    body = await res.json().catch(() => null);
  } else {
    body = await res.text().catch(() => null);
  }

  if (res.ok) {
    return body;
  } else {
    throw new AuthApiError(params, res.status, body);
  }
}
