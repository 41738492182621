import React from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from './login';
import Signup from './signup';
import ForgotPassword from './forgotpassword';
import Logout from './logout';
import Session from './session';
import GlobalStyles from '../shared/global';
import {
  sendMessage,
  getCognitoUserSession,
  crossOriginMessage,
  checkOrigin,
} from '../utils';

const history = createBrowserHistory();

function App() {
  crossOriginMessage.use(async (request) => {
    if (request === 'session') {
      const session = await getCognitoUserSession();
      if (session != null) {
        sendMessage('session', session)
      } else {
        history.push('/login')
        sendMessage('showModal', null)
      }
    }

    if (request === 'logout') {
      history.push('/logout');
      sendMessage('showModal', null);
    }
  });

  React.useEffect(() => {
    const listenToMessages = (e) => {
      const isValidOrigin = checkOrigin(e.origin);

      if (isValidOrigin !== false) {
        crossOriginMessage.emit(e.data.request);
      }
    }

    window.addEventListener('message', listenToMessages);

    return () => window.removeEventListener('message', listenToMessages);
  }, []);

  return (
    <main id="site-wrapper">
      <GlobalStyles />
      <Router history={history}>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/session" component={Session} />
        <Route path="/logout" component={Logout} />
        <Route path="/:temp/login" component={Login} />
        <Route path="/:temp/signup" component={Signup} />
        <Route path="/:temp/forgot-password" component={ForgotPassword} />
        <Route path="/:temp/session" component={Session} />
      </Router>
    </main>
  );
}

export default App;
